import React from 'react';
import PropTypes from 'prop-types';
import { User } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import styles from 'components/HomePage/Header/Account/MyAccount/MyAccount.scss';
import urls from 'constants/urls';

const MyAccount = ({ loggedIn }) =>
  loggedIn ? (
    <>
      <div className={styles.iconWrapper}>
        <User className={styles.signInIcon} />
      </div>
      <a className={styles.link} data-test="myaccount-link" href={urls.myAccount}>
        My Account
      </a>
    </>
  ) : null;

MyAccount.displayName = 'MyAccount';

MyAccount.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

export default MyAccount;
